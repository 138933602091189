<template>
    <div 
        ref="CKTextViewer"
        class="ck_text_viewer" 
        v-html="body" />
</template>

<script>

import eventBus from "@/utils/eventBus"

export default {
    props: {
        body: {
            type: String,
            default: ''
        }
    },
    methods: {
        addEventListenersToMentions() {
            const mentions = this.$refs['CKTextViewer'].querySelectorAll('[data-mention]')
            mentions.forEach(mention => {
                mention.addEventListener('click', event => {
                    const dataset = event.target.dataset
                    this.mentionClickHandler(dataset)
                })
            })
        },
        async mentionClickHandler(dataset) {
            if(dataset.type === 'task') {
                eventBus.$emit('OPEN_TASK_DRAWER', dataset.id)                
            }
        }
    },
    async mounted() {
        await this.addEventListenersToMentions()

        eventBus.$on('UPDATE_TEXT_VIEWER', async () => {
            await this.addEventListenersToMentions()
        })
    },
    beforeDestroy() {
        eventBus.$off('UPDATE_TEXT_VIEWER')
    }
}
</script>

<style lang="scss">
@import './scss/variables.scss';
.ck_text_viewer{
    @include ckeditorStyle;
    pre{
        background: var(--bgColor2);
        border: 1px solid var(--borderColor);
        border-radius: var(--borderRadius);
        overflow: auto;
        padding: 15px;
        width: auto;
        max-height: 600px;
        overflow-wrap: normal;
    }
}
</style>