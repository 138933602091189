<template>
    <Inquiries />
</template>

<script>
import Inquiries from '@apps/Inquiries'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Inquiries,
    }
}
</script>