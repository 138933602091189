<template>
    <div class="list-wrapper">
        <div class="filters check_button">
            <div>
                <div class="ordering">
                    <a-select
                        v-model="ordering"
                        allowClear
                        class="select"
                        placeholder="Сортировка"
                        @change="handleOrderingChange">
                        <a-select-option v-for="option, index in sortOptions" :key="index" :value="option.value">
                            {{ option.label }}
                        </a-select-option>
                    </a-select>
                </div>
                <a-checkbox
                    :checked="filters['new_inquiries']"
                    @change="newInquiriesFilterHandler($event, 'new_inquiries')">
                    Новые
                </a-checkbox>
                <a-checkbox
                    :checked="filters['processed_inquiries']"
                    @change="processedInquiriesFilterHandler($event, 'processed_inquiries')">
                    Обработанные
                </a-checkbox>
                <a-checkbox
                    class="slide_2"
                    :checked="filters['current_month']"
                    @change="currentMonthFilterHandler($event, 'current_month')">
                    Текущий месяц
                </a-checkbox>
                <a-checkbox
                    :checked="filters['current_year']"
                    @change="currentYearFilterHandler($event, 'current_year')">
                    Текущий год
                </a-checkbox>
                <a-checkbox
                    :checked="filters['period']"
                    @change="periodHandler($event, 'period')">
                    Период
                </a-checkbox>
                <div v-show="!setPeriodDesabled" class="set_period">
                    <a-range-picker
                        v-model="periodRange"
                        :disabled="setPeriodDesabled"
                        class="ml-2"
                        :getPopupContainer="trigger => trigger.parentElement"
                        :placeholder="['начало', 'конец']"
                        format="DD.MM.YYYY"
                        @change="periodChange" />
                </div>
            </div>
            <div class="flex items-center">
                <div 
                    class="mr-4 text-sm">
                    Всего: {{ summaryTotal }}
                </div>
                <div 
                    class="summary_item bg_white"
                    :class="summaryActive['white'] && 'active'"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="0 баллов"
                    @click="setSummaryFilters('white')">
                    <div class="bg"></div>
                    <span>{{ summaryWhite }}</span>
                </div>
                <div 
                    class="summary_item bg_yellow"
                    :class="summaryActive['yellow'] && 'active'"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="1-2 баллов"
                    @click="setSummaryFilters('yellow')">
                    <div class="bg"></div>
                    <span>{{ summaryYellow }}</span>
                </div>
                <div 
                    class="summary_item bg_orange"
                    :class="summaryActive['orange'] && 'active'"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="3-5 баллов"
                    @click="setSummaryFilters('orange')">
                    <div class="bg"></div>
                    <span>{{ summaryOrange }}</span>
                </div>
                <div 
                    class="summary_item bg_red"
                    :class="summaryActive['red'] && 'active'"
                    v-tippy="{ inertia : true, duration : '[600,300]'}"
                    content="6-10 баллов"
                    @click="setSummaryFilters('red')">
                    <div class="bg"></div>
                    <span>{{ summaryRed }}</span>
                </div>
            </div>
        </div>
        <div class="organization-filter">
            <a-radio-group v-model="organizationsFilter" @change="handleOrganizationsFilterChange">
                <a-radio value="only_my">
                    Организация
                </a-radio>
                <a-radio value="all">
                    Со структурными подразделениями
                </a-radio>
                <a-radio value="descendants">
                    Структурные подразделения
                </a-radio>
            </a-radio-group>
        </div>
        <a-spin :spinning="loading">
            <div v-if="list.length > 0" class="inquiries-list">
                <InquirItem
                    v-for="assessment in list"
                    :key="assessment.id"
                    :assessment="assessment"
                    class="mb-5 border custom_border_color overflow-hidden rounded-lg" />
                <div class="flex justify-end pt-1">
                    <a-pagination
                        :current="page"
                        size="small"
                        :show-size-changer="pageSizeOptions.length > 1"
                        :page-size.sync="pageSize"
                        :defaultPageSize="Number(pageSize)"
                        :pageSizeOptions="pageSizeOptions"
                        :total="count"
                        show-less-items
                        @showSizeChange="sizeSwicth"
                        @change="changePage">
                        <template slot="buildOptionText" slot-scope="props">
                            {{ props.value }}
                        </template>
                    </a-pagination>
                </div>
            </div>
            <a-empty v-else :description="false" />
        </a-spin>
    </div>
</template>

<script>
import InquirItem from "./InquirItem.vue"
import methodsM from "./mixins/methods"
import eventBus from '@/utils/eventBus'

export default {
    name: 'InquiriesList',
    components: {
        InquirItem,
    },
    mixins: [
        methodsM,
    ],
    props: {
        page_name: {
            type: String,
            default: ''
        },
        model: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            filters: {
                current_month: false,
                current_year: false,
                period: false,
                processed_inquiries: false,
                new_inquiries: false,
                organizations: false,
                total_value_filter: false
            },
            organizationsFilter: null,
            sortOptions: [
                {
                    label: 'По номеру А..я',
                    value: 'issue__number'
                },
                {
                    label: 'По номеру Я..а',
                    value: '-issue__number'
                },
                {
                    label: 'Сначала новые',
                    value: '-issue__issue_date'
                },
                {
                    label: 'Сначала старые',
                    value: 'issue__issue_date'
                }
            ],
            ordering: null,
            report: {},
            loading: false,
            list: [],
            page: 1,
            pageSize: 15,
            count: 0,
            pageSizeOptions: ['15', '30', '50'],
            periodStart: null,
            periodEnd: null,
            periodRange: [null, null],
            summary: null,
            summaryActive: {}
        }
    },

    computed: {
        filterActive() {
            return this.$store.state.filter.filterActive[this.page_name]
        },
        setPeriodDesabled() {
            return !this.filters.period
        },
        summaryTotal() {
            return this.summary?.total || 0
        },
        summaryWhite() {
            return this.summary?.white || 0
        },
        summaryYellow() {
            return this.summary?.yellow || 0
        },
        summaryOrange() {
            return this.summary?.orange || 0
        },
        summaryRed() {
            return this.summary?.red || 0
        },
    },
    methods: {
        async getList() {
            try {
                this.loading = true

                let params = {
                    page: this.page,
                    page_size: this.pageSize,
                    page_name: this.page_name
                }

                const { data } = await this.$http.get('/risk_assessment/', {
                    params
                })
                if(data?.results?.length) {
                    this.count = data.count
                    this.list = await this.setActions(data.results)
                    this.summary = data.summary
                } else {
                    this.list = []
                    this.count = 0
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        sizeSwicth(current, pageSize) {
            this.page = 1
            this.pageSize = Number(pageSize)
            this.getInquiries()
        },
        changePage(page) {
            this.page = page
            this.getInquiries()
        },
        currentMonthFilterHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(value) {
                if(this.filters['current_year'])
                    this.filters['current_year'] = false
                if(this.filters['period']) {
                    this.filters['period'] = false
                    this.periodStart = null
                    this.periodEnd = null
                    this.periodRange = [null, null]
                }
            }
            this.setFilters()
        },
        currentYearFilterHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(value) {
                if(this.filters['current_month'])
                    this.filters['current_month'] = false
                if(this.filters['period']) {
                    this.filters['period'] = false
                    this.periodStart = null
                    this.periodEnd = null
                    this.periodRange = [null, null]
                }
            }
            this.setFilters()
        },
        periodHandler(e, key) {
            const value = e.target.checked
            this.filters[key] = value
            if(value) {
                if(this.filters['current_month'])
                    this.filters['current_month'] = false
                if(this.filters['current_year'])
                    this.filters['current_year'] = false
            } else {
                this.periodStart = null
                this.periodEnd = null
                this.periodRange = [null, null]
                this.setFilters()
            }
            if(this.periodStart && this.periodEnd)
                this.setFilters()
        },
        periodChange(dates, dateStrings) {
            this.periodStart = dates[0]
            this.periodEnd = dates[1]
            if(this.periodStart && this.periodEnd) {
                this.setFilters()
            }
        },
        setFilters() {
            let date_values = [],
                status_value = [],
                organizations_value = ''
            
            if(this.filters['current_month']) {
                date_values = {
                    start: this.$moment().startOf('month').format('YYYY-MM-DD'),
                    end: this.$moment().endOf('month').format('YYYY-MM-DD')
                }
            } else if(this.filters['current_year']) {
                date_values = {
                    start: this.$moment().startOf('year').format('YYYY-MM-DD'),
                    end: this.$moment().endOf('year').format('YYYY-MM-DD')
                }
            } else if(this.filters['period']) {
                date_values = {
                    start: this.periodStart.format('YYYY-MM-DD'),
                    end: this.periodEnd.format('YYYY-MM-DD')
                }
            } else {
                date_values = []
            }

            if(this.filters['new_inquiries']) {
                status_value = ['new',]
            } else if(this.filters['processed_inquiries']) {
                status_value = ['processed',]
                
            } else {
                status_value = []
            }
            if(this.filters['organizations']) {
                organizations_value = this.organizationsFilter
            }
            eventBus.$emit(`send_include_fields_${this.page_name}`, {
                fields: {
                    issue_date_filter: {
                        active: this.filters['current_month'] || this.filters['current_year'] || this.filters['period'],
                        values: date_values
                    },
                    status: {
                        active: this.filters['new_inquiries'] || this.filters['processed_inquiries'],
                        values: {
                            value: status_value
                        }
                    },
                    organizations_filter: {
                        active: this.filters['organizations'],
                        values: {
                            value: organizations_value
                        }
                    },
                    total_value_filter: {
                        active: Object.keys(this.summaryActive).length !== 0,
                        values: {
                            value: Object.keys(this.summaryActive)
                        }
                    }
                }
            })
            
        },
        reloadList() {
            this.page = 1
            this.list = []
            this.getInquiries()
        }
    },
    created() {
        this.filtersInit()
        this.getInquiries()

        eventBus.$on('assessment_list_reload', () => {
            this.reloadList()
        })
        
        eventBus.$on(`update_filter_${this.model}`, () => {
            this.reloadList()
        })

        eventBus.$on('update_assessment_in_list', data => {
            this.updateAssessmentInList(data)
            this.getSummary()
        })

    },
    beforeDestroy() {
        eventBus.$off('assessment_list_reload')
        eventBus.$off('update_assessment_in_list')
        eventBus.$off(`update_filter_${this.model}`)

    },
}
</script>
<style lang="scss" scoped>
.summary_item {
    min-width: 40px;
    padding: 2px 8px;
    border-radius: 99999px;
    font-weight: 500;
    text-align: center;
    font-size: 0.875rem;
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
    user-select: none;
    cursor: pointer;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
    &.active{
        .bg{
            opacity: 0.6;
        }
        border-color: var(--blue);
    }
    span{
        position: relative;
        z-index: 5;
    }
    &.bg_white{
        .bg{
            background-color: #eeeeee;
        }
    }
    &.bg_yellow{
        .bg{
            background-color: #ffe936;
        }
    }
    &.bg_orange{
        .bg{
            background-color: #ff9231;
        }
    }
    &.bg_red{
        .bg{
            background-color: #ff4e46;
        }
    }
}
.summary_item:not(:last-child) {
    height: 26px;
    margin-right: 0.5rem;
}
.list-wrapper{
    &::v-deep {
        .check_button{
            .ant-checkbox-wrapper{
                margin: 8px 8px 0 0;
                .ant-checkbox{
                    display: none;
                    & + span{
                        height: 26px;
                        padding: 2px 8px;
                        border: 1px solid var(--borderColor);
                        border-radius: 30px;
                        display: inline-block;
                        background: #e3e6ea;
                        cursor: pointer;
                        -moz-user-select: none;
                        -khtml-user-select: none;
                        user-select: none;
                        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                        &:hover{
                            background: var(--primaryHover);
                        }
                    }
                    &.ant-checkbox-checked{
                        & + span{
                            background: var(--primaryHover);
                            color: var(--blue);
                        }
                    }
                }
            }
    
        }
    }
    .filters{
        min-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        line-height: 1.6;
        .set_period {
            display: inline;
            .ant-calendar-picker{
                margin-top: 8px;
            }
        }
        .ordering {
            display: inline;
            margin-right: 8px;
            .select{
                width: 150px;
            }
        }
    }
    .organization-filter{
        min-height: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .ant-radio-wrapper{
            margin: 8px 8px 0 0;
        }
    }
    .inquiries-list {
        .custom_border_color {
            border-color: var(--bgColor6);
        }
    }
}
</style>